<template>
    <img src="../../assets/icons/modalDeleteTrash.svg" alt="deleteIcon" role="alert">
</template>
<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'trashIcon',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    return {
    };
  },
});
</script>
<style lang="scss">
</style>
