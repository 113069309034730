<template>
  <!-- 首次进页面时 加载loading -->
  <Load :loading="loading" v-if="loading" />
  <!-- 首次进页面后 调API 返回为null -->
  <div v-else-if="loadingNull" class="empty-cart">
    <img src="@/assets/icons/emptyCart.png" alt="emptyCart" />
    <p>{{ t('cart.isEmpty') }}</p>
    <router-link to="/">
      <a-button type="primary" aria-label="Start Shopping">
        <span>{{ t('cart.startShopping') }}</span>
      </a-button>
    </router-link>
  </div>
  <!-- 购物车页面不为空-->
  <div
    v-else-if="cartLists !== undefined && cartLists.length > 0"
    class="shopping-cart"
  >
    <a-spin :spinning="isLoading">
      <template #indicator>
        <Loading />
      </template>
      <div class="shopping-cart-container">
        <div class="left-shopping-cart">
          <div class="cart-header">
            <div class="title">{{ t('cart.shoppingCart') }}</div>
            <div class="cart-info">
              <p class="info-quantity">{{ t('cart.quantity') }}</p>
              <p class="info-price">{{ t('cart.price') }}</p>
            </div>
          </div>
          <div class="cart-body">
            <div class="add-cart" v-for="item in cartLists" :key="item.goodsId">
              <div class="order-items">
                <div class="img-wrap">
                  <img
                    :src="`${item.goodsPic}?x-oss-process=image/resize,w_75,h_75`"
                    :alt="item.goodsName"
                  />
                </div>
                <div class="order-detail">
                  <div class="description">
                    <div class="d-up">
                      <span>{{ item.goodsName }}</span>
                      <span>{{ item.skuAttr.capacity }}</span>
                    </div>
                    <div class="d-down">
                      <span
                        v-for="attr in Object.values(item.skuAttr)"
                        :key="attr"
                      >
                        {{ attr }}
                      </span>
                    </div>
                    <a
                      class="remove-order-items"
                      @click="showDeleteConfirm(item)"
                    >
                      <img
                        src="@/assets/icons/trash.svg"
                        alt="remove-order-items"
                      />
                      <p>{{ t('cart.remove') }}</p>
                    </a>
                  </div>
                  <div class="order-quantity-price">
                    <!-- 当quantity 大于 realityStock时  -->
                    <a-select
                      v-if="item.quantity > item.realityStock"
                      :value="item.realityStock"
                      @change="(value) => quantityOnChange(item, value)"
                      :getPopupContainer="
                        (triggerNode) => {
                          return triggerNode.parentNode;
                        }
                      "
                    >
                      <a-select-option
                        v-for="i in item.realityStock"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </a-select-option>
                    </a-select>

                    <a-select
                      v-else
                      :value="item.quantity"
                      @change="(value) => quantityOnChange(item, value)"
                      :getPopupContainer="
                        (triggerNode) => {
                          return triggerNode.parentNode;
                        }
                      "
                    >
                      <a-select-option
                        v-for="i in item.realityStock"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </a-select-option>
                    </a-select>
                    <div class="price">{{t('global.currency')}}{{ item.sellPrice }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-summary">
          <div class="title">{{ t('cart.summary') }}</div>
          <div class="sub-total">
            <div class="label">{{ t('cart.subtotal') }}</div>
            <div class="price">{{t('global.currency')}}{{ cartPrice.marketPrice }}</div>
          </div>
          <div class="saving">
            <div class="label">{{ t('cart.saving') }}</div>
            <div class="price">{{t('global.currency')}}{{ cartPrice.reducedPrice }}</div>
          </div>
          <a-divider />
          <div class="total">
            <div class="label">{{ t('cart.total') }}</div>
            <div class="price">{{t('global.currency')}}{{ cartPrice.sellPrice }}</div>
          </div>
          <a-button
            type="primary"
            @click="onSubmit"
            aria-label="Proceed to Checkout "
            >{{ t('cart.toCheckout') }}
          </a-button>
          <div class="payment">
            <img v-for="img in payment" :key="img" :src="img" alt="paymentImg">
          </div>
        </div>
      </div>
    </a-spin>
  </div>

  <!-- 购物车页面为空 -->
  <div v-else class="empty-cart">
    <img src="@/assets/icons/emptyCart.png" alt="emptyCart" />
    <p>{{ t('cart.isEmpty') }}</p>
    <router-link to="/">
      <a-button type="primary" aria-label="Start Shopping">
        <span>{{ t('cart.startShopping') }}</span>
      </a-button>
    </router-link>
  </div>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    class="login-modal"
    :closable="false"
  >
    <login :isModal="true" @close="closeModal" />
  </a-modal>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  Divider, Button, Modal, Input, Spin, Select,
} from 'ant-design-vue';
import {
  createVNode,
  defineComponent,
  onBeforeMount,
  computed,
  ref,
} from 'vue';
import Loading from '@/components/Alert/Loading.vue';
import Load from '@/components/common/Load.vue';
import { useStore } from 'vuex';
import trashIcon from '@/components/Alert/trashIcon.vue';
import '@/components/Alert/cartDeleteConfirm.scss';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { Cart } from '@/services/interface/response';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/components/Alert';
import Login from './Login.vue';

export default defineComponent({
  name: 'ShoppingCart',
  components: {
    Login,
    ...importComponents(Divider, Button, Modal, Input, Spin, Select),
    'a-select-option': Select.Option,
    Loading,
    Load,
  },

  setup() {
    const { dispatch, state } = useStore();
    const cartLists = computed(() => state.cartStore.cartItems);
    const cartPrice = computed(() => state.cartStore.cartPrice);
    const cartVistor = computed(() => state.cartNumber);

    const { t } = useI18n();

    // 增加购物车数量之后的 loading效果
    const isLoading = ref(false);
    const loading = ref(true);
    const loadingNull = ref(false);

    // 登录弹窗的显示与否
    const visible = ref(false);

    const router = useRouter();

    // 删除购物车个别商品弹窗提示
    const showDeleteConfirm = (item: Cart) => {
      if (Cookies.get('token')) {
        Modal.confirm({
          title: t('cart.removeMessage'),
          icon: createVNode(trashIcon),
          maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.67)' },
          okText: t('cart.yes'),
          cancelText: t('cart.no'),
          onOk() {
            dispatch('removeCartItem', item.cartId);
          },
          onCancel() {
            // console.log('cancel');
          },
          class: 'delete-confirm',
        });
      } else {
        Modal.confirm({
          title: t('cart.removeMessage'),
          icon: createVNode(trashIcon),
          maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.67)' },
          okText: t('cart.yes'),
          cancelText: t('cart.no'),
          onOk() {
            dispatch('changeOrRemoveVisitorCart', {
              skuId: item.skuId,
              goodsId: item.goodsId,
              quantity: 0,
            }).then(() => {
              if (state.ShoppingCart.length !== 0) {
                dispatch('getVisitorCart');
              } else {
                dispatch('cleanCart');
              }
            });
          },
          onCancel() {
            // console.log('cancel');
          },
          class: 'delete-confirm',
        });
      }
    };

    // 跳到checkout
    const onSubmit = async () => {
      // if (!Cookies.get('token')) {
      //   visible.value = true;
      // } else {
      const isNoStock = (cartLists.value as Cart[]).some((item) => item.quantity === 0);
      if (isNoStock) {
        const modal = Alert.error(t('cart.soldOut'));
        setTimeout(() => modal.destroy(), 2000);
      } else {
        router.push('/checkout');
      }
      // }
    };

    const closeModal = () => {
      visible.value = false;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const quantityOnChange = (item: any, value: number) => {
      isLoading.value = true;
      if (Cookies.get('token')) {
        dispatch('updateCartItems', {
          cartId: item.cartId,
          goodsId: item.goodsId,
          quantity: value,
        }).then(() => {
          isLoading.value = false;
        });
      } else {
        dispatch('changeOrRemoveVisitorCart', {
          skuId: item.skuId,
          goodsId: item.goodsId,
          quantity: value,
        })
          .then(() => dispatch('getVisitorCart'))
          .then(() => {
            isLoading.value = false;
          });
      }
    };

    // payment 支持支付的图标
    const payment = [
      './images/paymentCard/Visa-light@2x.png',
      './images/paymentCard/MasterCard-light@2x.png',
      './images/paymentCard/AmericanExpress-light@2x.png',
      './images/paymentCard/Paypal-light@2x.png',
      './images/paymentCard/JCB-light@2x.png',
      // './images/paymentCard/UnionPay-light@2x.png',
    ];

    // 有token的时候 和 没有token的时候
    onBeforeMount(() => {
      if (Cookies.get('token')) {
        dispatch('getCartItems')
          .then(() => {
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            loadingNull.value = true;
          });
      } else {
        dispatch('getVisitorCart')
          .then(() => {
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            loadingNull.value = true;
          });
      }
    });

    return {
      showDeleteConfirm,
      onSubmit,
      cartLists,
      cartPrice,
      quantityOnChange,
      isLoading,
      loading,
      visible,
      closeModal,
      cartVistor,
      loadingNull,
      t,
      payment,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

.shopping-cart {
  position: relative;
  z-index: 1;
  background-color: $container-background-color;
  padding: 50px 0 156px;
  border-bottom: 3px solid $footer-color;

  :deep(.ant-spin) {
    max-height: none !important;
    .ant-spin-dot {
      position: sticky;
    }
  }

  .shopping-cart-container {
    @include main-container();
    width: 1280px;
    padding: 0 0 131px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .left-shopping-cart {
      width: 893px;
      @include card-border();
      border: 1px solid #f0f0f0;

      .cart-header {
        padding: 0 24px 14px 24px;
        .title {
          color: $theme-color;
          font-size: 25px;
          font-weight: 600;
          line-height: 30px;
          border-bottom: none;
          padding: 35px 0 5px 13px;
        }
        .cart-info {
          display: flex;
          justify-content: space-between;
          width: 37%;
          margin-right: 14px;
          line-height: 17px;
          margin-left: auto;
          p {
            display: inline;
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      :deep(.ant-spin) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .cart-body {
        padding-bottom: 35px;
        .add-cart {
          padding: 36px 21px 15px 16px;
          border-top: 1px solid hsla(0, 0%, 93%, 1);
          .order-items {
            display: flex;
            justify-content: space-between;
            .img-wrap {
              width: 100px;
              margin-right: 37px;
              margin-left: 10px;
              text-align: center;
              img {
                border-radius: 4px;
              }
            }
            .order-detail {
              display: flex;
              align-items: center;
              flex: 1;
              justify-content: space-between;
              .description {
                display: flex;
                flex-direction: column;
                margin-top: 6px;
                .d-up {
                  display: flex;
                  font-size: 14px;
                  font-weight: 500;
                  color: #545454;
                  line-height: 17px;
                  margin-bottom: 15px;
                  span {
                    margin-right: 5px;
                  }
                }
                .d-down {
                  display: flex;
                  font-size: 12px;
                  font-weight: 500;
                  color: #3962ad;
                  line-height: 15px;
                  span {
                    margin-right: 6px;
                  }
                }
                .remove-order-items {
                  display: flex;
                  margin-top: 26px;
                  img {
                    width: 17px;
                    height: 17px;
                    margin-right: 10px;
                  }
                  p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #545454;
                    line-height: 15px;
                    margin-top: 1px;
                    margin-bottom: 0px;
                  }
                }
              }
              .order-quantity-price {
                display: flex;
                justify-content: space-between;
                width: 48%;
                margin-right: 14px;
                margin-bottom: 35px;
                padding: 0;

                :deep(.ant-select) {
                  width: 81px;
                  height: 28px;
                  .ant-select-selector {
                    // 解决失焦瞬间边框变蓝
                    border-color: none !important;
                    border: 1px solid #f5f5f5 !important;
                    box-shadow: none;
                    &:hover {
                      border: 1px solid #f5f5f5 !important;
                      box-shadow: none;
                    }
                    &:focus {
                      border: 1px solid #f5f5f5 !important;
                    }
                    &:focus-within {
                      border: 1px solid #f5f5f5 !important;
                    }
                    .ant-select-selection-item {
                      text-align: center;
                    }
                  }
                  .ant-select-item-option-content {
                    text-align: center;
                  }
                }
              }
              .price {
                font-size: 18px;
                font-weight: 800;
                color: #E65050;
                line-height: 21px;
              }
            }
          }
        }
      }
    }

    .right-summary {
      width: 357px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.16);
      border-radius: 12px;
      padding: 37px;
      .ant-divider.ant-divider-horizontal {
        width: 126%;
        min-width: 126%;
        background-color: #ededed;
        right: 37px;
        margin: unset;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #E65050;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .sub-total,
      .saving {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:18px;
        .label {
          font-size: 14px;
          font-weight: 500;
          color: #545454;
          line-height: 17px;
        }
        .price {
          font-size: 18px;
          font-weight: 500;
          color: #1c1c1c;
          line-height: 21px;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top:25px;
        margin-bottom:30px;
        .label {
          font-size: 16px;
          font-weight: 500;
          color: #545454;
          line-height:20px;
        }
        .price {
          font-weight: 800;
          color: #1c1c1c;
          font-size: 20px;
          line-height: 24px;
        }
      }
      .ant-btn.ant-btn-primary {
        width: -webkit-fill-available;
        height: 44px;
        font-size: 14px;
        font-weight: bold;
        border-color: $theme-color;
        background: $theme-color;
        border-radius: 4px;
      }
      .payment{
        display: flex;
        justify-content: center;
        margin-top: 21px;
        img{
          width:43px;
          height:26px;
          margin-left:5px;
        }
      }

    }
  }
}

.empty-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 158px;
  p {
    margin-top: 20px;
    margin-bottom: 79px;
    font-size: 20px;
    font-weight: 500;
    color: #545454;
    line-height: 24px;
  }
  .ant-btn {
    height: 45px;
    border-radius: 4px;
    border: 1px solid $theme-color;
    background: none;
    margin-bottom: 64px;
    span {
      font-size: 20px;
      font-weight: 500;
      color: $theme-color;
      line-height: 24px;
    }
  }
}
</style>
