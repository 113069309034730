
import importComponents from '@/utils/import-components';
import {
  Divider, Button, Modal, Input, Spin, Select,
} from 'ant-design-vue';
import {
  createVNode,
  defineComponent,
  onBeforeMount,
  computed,
  ref,
} from 'vue';
import Loading from '@/components/Alert/Loading.vue';
import Load from '@/components/common/Load.vue';
import { useStore } from 'vuex';
import trashIcon from '@/components/Alert/trashIcon.vue';
import '@/components/Alert/cartDeleteConfirm.scss';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { Cart } from '@/services/interface/response';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/components/Alert';
import Login from './Login.vue';

export default defineComponent({
  name: 'ShoppingCart',
  components: {
    Login,
    ...importComponents(Divider, Button, Modal, Input, Spin, Select),
    'a-select-option': Select.Option,
    Loading,
    Load,
  },

  setup() {
    const { dispatch, state } = useStore();
    const cartLists = computed(() => state.cartStore.cartItems);
    const cartPrice = computed(() => state.cartStore.cartPrice);
    const cartVistor = computed(() => state.cartNumber);

    const { t } = useI18n();

    // 增加购物车数量之后的 loading效果
    const isLoading = ref(false);
    const loading = ref(true);
    const loadingNull = ref(false);

    // 登录弹窗的显示与否
    const visible = ref(false);

    const router = useRouter();

    // 删除购物车个别商品弹窗提示
    const showDeleteConfirm = (item: Cart) => {
      if (Cookies.get('token')) {
        Modal.confirm({
          title: t('cart.removeMessage'),
          icon: createVNode(trashIcon),
          maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.67)' },
          okText: t('cart.yes'),
          cancelText: t('cart.no'),
          onOk() {
            dispatch('removeCartItem', item.cartId);
          },
          onCancel() {
            // console.log('cancel');
          },
          class: 'delete-confirm',
        });
      } else {
        Modal.confirm({
          title: t('cart.removeMessage'),
          icon: createVNode(trashIcon),
          maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.67)' },
          okText: t('cart.yes'),
          cancelText: t('cart.no'),
          onOk() {
            dispatch('changeOrRemoveVisitorCart', {
              skuId: item.skuId,
              goodsId: item.goodsId,
              quantity: 0,
            }).then(() => {
              if (state.ShoppingCart.length !== 0) {
                dispatch('getVisitorCart');
              } else {
                dispatch('cleanCart');
              }
            });
          },
          onCancel() {
            // console.log('cancel');
          },
          class: 'delete-confirm',
        });
      }
    };

    // 跳到checkout
    const onSubmit = async () => {
      // if (!Cookies.get('token')) {
      //   visible.value = true;
      // } else {
      const isNoStock = (cartLists.value as Cart[]).some((item) => item.quantity === 0);
      if (isNoStock) {
        const modal = Alert.error(t('cart.soldOut'));
        setTimeout(() => modal.destroy(), 2000);
      } else {
        router.push('/checkout');
      }
      // }
    };

    const closeModal = () => {
      visible.value = false;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const quantityOnChange = (item: any, value: number) => {
      isLoading.value = true;
      if (Cookies.get('token')) {
        dispatch('updateCartItems', {
          cartId: item.cartId,
          goodsId: item.goodsId,
          quantity: value,
        }).then(() => {
          isLoading.value = false;
        });
      } else {
        dispatch('changeOrRemoveVisitorCart', {
          skuId: item.skuId,
          goodsId: item.goodsId,
          quantity: value,
        })
          .then(() => dispatch('getVisitorCart'))
          .then(() => {
            isLoading.value = false;
          });
      }
    };

    // payment 支持支付的图标
    const payment = [
      './images/paymentCard/Visa-light@2x.png',
      './images/paymentCard/MasterCard-light@2x.png',
      './images/paymentCard/AmericanExpress-light@2x.png',
      './images/paymentCard/Paypal-light@2x.png',
      './images/paymentCard/JCB-light@2x.png',
      // './images/paymentCard/UnionPay-light@2x.png',
    ];

    // 有token的时候 和 没有token的时候
    onBeforeMount(() => {
      if (Cookies.get('token')) {
        dispatch('getCartItems')
          .then(() => {
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            loadingNull.value = true;
          });
      } else {
        dispatch('getVisitorCart')
          .then(() => {
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
            loadingNull.value = true;
          });
      }
    });

    return {
      showDeleteConfirm,
      onSubmit,
      cartLists,
      cartPrice,
      quantityOnChange,
      isLoading,
      loading,
      visible,
      closeModal,
      cartVistor,
      loadingNull,
      t,
      payment,
    };
  },
});
